import React, {Component} from "react";
import {Button, Grid, Header, Image, Modal} from "semantic-ui-react";
import {connect} from "react-redux";
import {rejectIfAnyNull} from "../controllers/utilities/env-checks";
import AndroidComm from "../controllers/utilities/unity-android-communication";
import {formatQuery} from "../controllers/utilities/string-utils";
import {getAdItem, setAdItem, getAdItemByEventId, ImgVideo, ImgVideoSmall} from "../components/lib/ad-manage-api-lib";
import KaisakuUserApi from "../controllers/kaisaku-user-api";
import {navigate} from "../../.cache/gatsby-browser-entry";
import {getUA} from "react-device-detect";
import {AdAregionSwipetoslide} from "../components/react-slick/ad-aregion-swipetoslide";
import {AdBcdregionSwipetoslide} from "../components/react-slick/ad-bcdregion-swipetoslide";
import "../styles/bg.css";
import noodle1 from "../images/Artboard-noodle1-unionpay.jpg";
import noodle2 from "../images/Artboard-noodle2-unionpay.jpg";
import cookie from 'react-cookies';


export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings,
    };
})(class extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            startTime: 0,
            endTime: 0,
            transactionId: '',
            userId:'',
            gameId:'',
            eventId: '',
            accessToken: '',
            nickname:'',
            createdAt:'',
            expireAt:'',
            expireAt_TTL:'',
            divStyle: ''
        };
        
        let { startTime, endTime, transactionId, eventId, userId ,gameId, accessToken, nickname, createdAt, expireAt, expireAt_TTL} = formatQuery(this.props.location.search);
        this.state.startTime = startTime;
        this.state.endTime = endTime;
        this.state.transactionId = transactionId;

        this.state.userId = userId;
        this.state.gameId = gameId;
        this.state.accessToken = accessToken;
        this.state.nickname = nickname;
        this.state.createdAt = createdAt;
        this.state.expireAt = expireAt;
        this.state.expireAt_TTL = expireAt_TTL;
        if (eventId === undefined) eventId = "";
        this.state.eventId = eventId;
        // 保存弹出时间
        if (!cookie.load("unionpay" + accessToken)){
            cookie.save("unionpay" + accessToken, "unionpay" + accessToken, {
                path: "/",
                expires: new Date(new Date().getTime() + 0.5 * 3600 * 1000) 
            });
        }
    }

    async closeAd() {
        
    }

    render() {
        let noodle = noodle1;    
        const mode = detectOrient();
        let width = "80vw";
        let height = "98vw";
            if (mode === "LANDSCAPE"){
                noodle = noodle2;
                width = "90vh";
                height = "38vh";
            }

        return (
            <Grid textAlign='center' style={{
                width: '100vw',
                height: "100vh",
                background: "transparent",
                padding: "3vh"
            }} verticalAlign='middle'>
                <Grid.Column style={{width: '100vw',
                height: "100vh"}}>
                    <Modal
                        style={{width:`${width}`, height:`${height}`,borderRadius:'10px 10px 10px 10px'}}
                        open
                    >
                     <div style={{width:`${width}`}} >
                            <div style={{height:'37px',textAlign:'center',fontSize:'14px',fontWeight:'bold',
                            backgroundColor:"#ffa101",
                            margin: "-78px 0 0",
                                paddingTop:'8px',border:'1px solid #D6D6D6',
                                borderRadius:'10px 10px 0px 0px',
                                opacity:'1'}}                                 
                            >
                                <Timer endTime={this.state.endTime} />
                            </div>
                            <div style={{height:"100%"}}
                        onClick={async () => {
                            let platform = cookie.load("_platform");
                            let url = `/confirm-purchase/${this.props.location.search}`;
                            if (this.props.location.search.indexOf("platform") === -1){
                                url += `&platform=${platform}`;
                            }                            
                            window.location.href = url;
                        }}
                            >
                                {(mode === "LANDSCAPE") && (<Image src={noodle} style={{width:'90vh',height:'60vh',objectFit:'fill'}}/>)}
                                {(mode === "PORTRAIT") && (<Image src={noodle} style={{width:'80vw',height:'120vw',objectFit:'fill'}}/>)}
                                

                            </div>

                            <div style={{height:'41px',textAlign:'center',fontSize:'1.35em',fontWeight:'bold',
                            backgroundColor:"#ffa101",
                                paddingTop:'10px',border:'1px solid #D6D6D6',
                                borderRadius:'0px 0px 10px 10px',
                                opacity:'1'}}
                                 onClick={async () => {
                                     this.closeAd();
                                     // console.log({ gameId:this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken,nickname: this.state.nickname,createdAt: this.state.createdAt, expireAt: this.state.expireAt });
                                     AndroidComm.sendSession({ gameId:this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken,nickname: this.state.nickname,createdAt: this.state.createdAt, expireAt: this.state.expireAt, expireAt_TTL: this.state.expireAt_TTL });   
                                 }}
                            >
                                <span style={{color:'#FFF'}}>狠心離開</span>
                            </div>
                     </div>   

                    </Modal>
                </Grid.Column>
            </Grid>);                    
    }

});    


function getBjTime(){
    let d = new Date();
    let local = d.getTime();
    let offset = d.getTimezoneOffset() * 60000;
    let localUtc = new Date().getTimezoneOffset() /60;
    let utcTime ;
    if(localUtc>0){
        utcTime = local - offset;
    }else{
        utcTime = local + offset;
    }
    let localTime = utcTime + 3600000 * Math.abs(8);
    return localTime;
}

function showHint(seconds){
    const seconds1 = 1000;
    const minutes = seconds1 * 60;
    const hour = minutes * 60;
    const day = hour * 24;
    let iDay = parseInt(seconds / day);
    let d = seconds - iDay * day;
    let iHour = parseInt(d / hour);
    d = seconds - iDay * day - iHour * hour;
    let iMinutes = parseInt(d / minutes);
    d = seconds - iDay * day - iHour * hour - iMinutes * minutes;
    let iseconds = parseInt(d / seconds1);
    let showTime1 = "優惠時間尚餘: ";
    if (iDay > 0){
        showTime1 = showTime1 + iDay + "天";
    }
    if (iHour > 0){
        showTime1 = showTime1 + iHour + "小時";
    }
    if (iMinutes > 0){
        showTime1 = showTime1 + iMinutes + "分";
    }
    if (iseconds > 0){
        showTime1 = showTime1 + iseconds + "秒";
    }
    if (seconds <= 1000){
        showTime1 = "已過期";
    }
    let color = "#ffffff";
    if (seconds < 60000){
        color = "#ff0000";
    }
    return "<span style='color:"+ color +"'>" + showTime1 + "</span>";
}

class Timer extends Component {
    state = {
        seconds: 0,
        showTime: ''
    };

    tick = () => {
        const { seconds } = this.state;
        let showTime1 = showHint(seconds);

        this.setState({
            seconds: seconds - 1000,
            showTime: showTime1
        })
    }

    componentDidMount() {
        // 定时器，可以修改1000为自己想要的时间，
        const {endTime} = this.props;
        this.state.seconds = endTime - getBjTime();

        this.interval = setInterval(() => this.tick(), 1000);
    }

    componentWillUnmount() {
        // 清除定时器
        clearInterval(this.interval);
        this.setState = () => false;
    }
    render() {
        const {endTime} = this.props;
        this.state.seconds = endTime - getBjTime();
        this.state.showTime = showHint(this.state.seconds);

        return (
            <div style={{fontSize: '1.25em'}} dangerouslySetInnerHTML={{ __html:this.state.showTime}}></div>
        )
    }
}


function detectOrient(){
    if(typeof window !== `undefined`) {
        // let isIOS = "0";
        // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
        //     // 暂时去掉ios两板功能
        //     isIOS = "1";
        // }
        // if (isIOS === "0") return "PORTRAIT";
        if (window.innerHeight >= window.innerWidth) {
            // 竖屏
            // console.log("竖屏");
            return "PORTRAIT";
        } else {
            // 横屏
            // console.log("横屏");
            return "LANDSCAPE";
        }
    }else{
        return "PORTRAIT";
    }
}